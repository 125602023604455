* {
  font-family: 'Poppins';
}
.unsubscribePage {
  max-width: 1440px;
  padding: 0 120px;
  margin: 0 auto;
  padding-bottom: 40px;
  display: flex;
  justify-content: flex-start;

  .unsubscribeContent {
    max-width: 480px;
    width: 100%;
  }

  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 112%;
    color: #f9fbff;
    margin-bottom: 20px;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 138%;
    letter-spacing: 0.02em;
    color: #f9fbff;
    opacity: 0.8;
    margin-bottom: 32px;
  }

  .email {
    display: flex;
    flex-direction: column;
    margin-bottom: 27px;
    h4 {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      color: #ffffff;
      opacity: 0.5;
      margin-bottom: 6px;
    }
    input {
      max-width: 400px;
      width: 100%;
      line-height: 50px;
      height: 50px;
      border-radius: 50px;
      padding: 0 20px;
      background: white;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #282a2e;
      outline: none;
    }
  }

  .checkbox {
    margin-bottom: 32px;

    input[type='checkbox'] {
      position: absolute;
      opacity: 0;
      & + label {
        display: flex;
        align-items: center;
        position: relative;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.02em;
        color: rgba(249, 251, 255, 0.8);
      }
      & + label:before {
        content: '';
        margin-right: 8px;
        display: inline-block;
        vertical-align: text-top;
        width: 16px;
        height: 16px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 3px;
        border: none;
      }
      &:checked + label:after {
        content: '\2714';
        font-size: 14px;
        position: absolute;
        top: 1px;
        left: 2px;
        color: #fff;
      }
    }
  }

  button {
    padding: 15px 34px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #ffffff;
    background: #9c6af8;
    border-radius: 40px;
    outline: none;
    border: none;
    cursor: pointer;

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  @media (max-width: 1200px) {
    padding: 0 50px 30px;
  }
  @media (max-width: 767px) {
    padding: 0 20px 40px;
    justify-content: center;

    h1,
    h3 {
      text-align: center;
    }
    p {
      word-break: break-all;
    }
  }
}
