.textPage {
  max-width: 1440px;
  padding: 0 120px;
  margin: 0 auto;
  padding-bottom: 40px;
  display: flex;
  justify-content: flex-start;

  .textContent {
    max-width: 640px;
    width: 100%;
  }

  h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 56px;
    line-height: 112%;
    margin: 41px 0;
    color: #f9fbff;
  }
  h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 112%;
    margin-bottom: 24px;
    color: #f9fbff;
  }
  p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 138%;
    margin-bottom: 24px;
    color: #f9fbff;
    span {
      text-decoration: underline;
    }
  }
  ul {
    color: white;
  }

  @media (max-width: 1200px) {
    padding: 0 50px 30px;
  }
  @media (max-width: 767px) {
    padding: 0 20px 40px;
    justify-content: center;

    h1,
    h3 {
      text-align: center;
    }
    p {
      word-break: break-all;
    }
  }

  @media (max-width: 500px) {
    h1 {
      font-size: 40px;
    }
  }
}
