.header,
.footer {
  display: flex;
  align-items: center;
  max-width: 1440px;
  padding: 0 120px;
  margin: 0 auto;
  padding-bottom: 40px;

  .logo {
    @media (max-width: 450px) {
      width: 160px;
    }
  }

  a,
  p {
    display: flex;
    text-decoration: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    align-items: center;
    text-transform: uppercase;
    color: #ffffff7f;
    margin-left: 18px;
    text-align: center;

    &.currentRoute,
    &:hover {
      color: white;
    }
  }

  @media (max-width: 1200px) {
    padding: 0 50px 30px;
  }
  @media (max-width: 767px) {
    padding: 0 20px 40px;
  }
}
.footer {
  position: absolute;
  bottom: 0;
  padding-bottom: 0;

  p {
    margin-left: 0;
    font-weight: 400;
  }
}

.hLine {
  display: flex;
  width: 2px;
  height: 12px;
  background-color: #727479;
  border-radius: 500px;
  margin-left: 12px;
}
