.homeContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1440px;
  padding: 0 120px 40px;
  margin: 0 auto;

  .content {
    display: flex;
    flex-direction: column;
    max-width: 640px;
    width: 100%;
    flex-shrink: 0;
    padding: 50px 0;
    padding-right: 30px;

    h1,
    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 56px;
      line-height: 112%;
      color: #f9fbff;
      margin-bottom: 20px;
    }
    h2 {
      font-size: 24px;
    }

    h5 {
      font-size: 12px;
      font-weight: 600;
      line-height: 15px;
      text-transform: uppercase;
      color: #ffffff7f;
      display: flex;
      align-items: center;
      margin-top: 36px;
    }

    & > p {
      max-width: 480px;
      font-weight: 400;
      font-size: 16px;
      line-height: 130%;
      letter-spacing: 0.02em;
      color: #f9fbff;
      opacity: 0.8;
      margin-bottom: 32px;
    }

    .emailBox,
    .aboutBox {
      display: flex;
      flex-direction: column;
      max-width: 200px;
      margin-right: 10px;

      span {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: #ffffff7f;
        margin-bottom: 8px;
      }

      input {
        border-radius: 30px;
        background: white;
        padding-left: 26px;
        border: none;
        outline: none;
        font-size: 14px;
        line-height: 50px;
        width: 100%;

        &::placeholder {
          color: #282a2e;
          opacity: 0.5;
        }
      }
    }
    .aboutBox {
      max-width: 270px;
      width: 100%;
    }
    .btnJoin {
      padding: 15px 40px;
      background: #9c6af8;
      border-radius: 40px;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #ffffff;
      cursor: pointer;

      &:hover {
        background: #752afe;
      }
    }
    .errBox {
      margin: 10px 0 0;
      color: red;
    }

    .inputGroup {
      display: flex;
      align-items: flex-end;

      .rankBox {
        padding: 16px 30px;
        border-radius: 30px;
        background: #e0f6fb;
        font-size: 14px;
        line-height: 18px;
        color: #282a2e;
      }

      .copyBox {
        padding: 4px;
        padding-left: 26px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 360px;
        width: 100%;
        background: white;
        border-radius: 30px;
        margin-left: 8px;
        position: relative;
        p {
          line-height: 1;
        }

        span {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 53px;
          padding-left: 10px;
          background-color: white;
          font-weight: 700;
          font-size: 12px;
          line-height: 40px;
          color: #282a2e;
        }

        img {
          cursor: pointer;
          margin-left: 10px;
          width: 42px;
          height: 42px;
          background: #9c6af8;
          border-radius: 50%;

          &:hover {
            background: #752afe;
          }
        }
      }

      @media (max-width: 970px) {
        flex-direction: column;
        align-items: flex-start;

        .copyBox {
          margin-left: 0;
          margin-top: 20px;
        }

        .emailBox,
        .aboutBox {
          max-width: 100%;
          width: 100%;
          margin-bottom: 16px;
        }
      }
      @media (max-width: 767px) {
        width: 100%;
        .copyBox {
          max-width: 100%;
          p {
            font-size: 16px;
          }
          img {
            width: 40px;
          }
        }
        .rankBox {
          width: 100%;
          font-size: 16px;
        }
        .btnJoin {
          width: 100%;
        }
      }
    }

    .stores {
      margin-top: 10px;
      display: flex;
      align-items: center;
      a:not(:first-child) {
        margin-left: 8px;
      }
      a {
        position: relative;

        span {
          background: #ff2f01;
          padding: 5px 8px;
          border-radius: 25px;
          // font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 10px;
          line-height: 15px;
          color: #ffffff;
          margin-left: 5px;
          position: absolute;
          top: 50%;
          transform: translateY(-55%);
          right: -35px;
        }
      }
    }
  }

  .phone {
    display: flex;
    justify-content: center;
    img {
      width: 314px;
    }
  }

  @media (max-width: 1200px) {
    padding: 0 50px 40px;
    .content {
      width: 70%;
      h1 {
        font-size: 40px;
      }
    }
    .phone {
      width: 30%;
      img {
        width: 100%;
      }
    }
  }

  @media (max-width: 767px) {
    flex-direction: column-reverse;
    padding: 0 20px 60px;
    .content {
      padding-right: 0;
      padding-bottom: 0;
      width: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #282a2e 100px);
      text-align: center;
      align-items: center;
      min-height: 560px;
      h1,
      h2 {
        max-width: 100%;
        font-size: 32px;
        margin-bottom: 18px;
      }
      h2 {
        font-size: 20px;
      }
      h5 {
        font-size: 12px;
      }
      & > p {
        font-size: 14px;
        max-width: 95%;
        margin-bottom: 24px;
      }
      .emailBox,
      .aboutBox {
        width: 100%;
      }
      .stores {
        flex-direction: column;
        a:not(:first-child) {
          margin: 12px 0 0;
        }
      }
    }
    .phone {
      width: 84%;
      z-index: -1;
      img {
        margin-bottom: -97%;
      }
    }
  }
  @media (max-width: 600px) {
    .content {
      min-height: auto;
    }
  }
}
